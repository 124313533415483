
body {
  margin: 0;
  /* background-image: url("background.jpeg"); */
}


/**
* CSS rules for D3 visualization
*/

.plot {
  overflow: scroll;
  direction: rtl
}

.signal_line {
  stroke: black;
  stroke-width: 2;
  fill: none
}

.baseline_line {
  stroke: red;
  stroke-width: 2;
  fill: none
}

.grid line {
  stroke: #22780f;
  opacity: 0.7;
}
.grid path {
  stroke: #22780f;
}

.x-axis {
  stroke: #22780f;
}
.x-axis path, line {
  opacity: 0.7;
}
.x-axis text {
  font-size: 14px;
  stroke: black;
}

.y-axis {
  stroke: #22780f;
}
.y-axis path, line {
  opacity: 0.7;
}
.y-axis text {
  font-size: 11px;
  opacity: 0.7;
}

.area_acc {
  fill: blue;
  opacity: 0.3
}
.area_dec {
  fill: orange;
  opacity: 0.3
}
